import Box from "@mui/material/Box";

import backgroundRegistry from "../../assets/backgroundRegistry.png";
import ContactCenterForm from "../../components/contactCenterForm";
import VideolinkHook from "../../components/videolinkHook";
import { consts } from "../../consts";

function DesktopView({
  t,
  lang,
  setLang,
  banner,
  handleChageBanner,
  principalColor,
  setPrincipalColor,
  openColorPicker,
  setOpenColorPicker,
  buttonColor,
  setButtonColor,
  openColorPickerButton,
  setOpenColorPickerButton,
  navigate,
  onUpdatePreferences,
  isLoading,
  showTour,
}) {
  return (
    <Box style={{ width: "100%", display: "flex" }}>
      <Box
        style={{
          width: "50%",
          height: "100vh",
          textAlign: "center",
          padding: 16,
          paddingTop: 24,
        }}
      >
        <ContactCenterForm
          t={t}
          lang={lang}
          setLang={setLang}
          banner={banner}
          handleChageBanner={handleChageBanner}
          principalColor={principalColor}
          setPrincipalColor={setPrincipalColor}
          openColorPicker={openColorPicker}
          setOpenColorPicker={setOpenColorPicker}
          buttonColor={buttonColor}
          setButtonColor={setButtonColor}
          openColorPickerButton={openColorPickerButton}
          setOpenColorPickerButton={setOpenColorPickerButton}
          onUpdatePreferences={onUpdatePreferences}
          navigate={navigate}
          isLoading={isLoading}
          showTour={showTour}
        />
      </Box>
      <Box
        style={{
          height: "100vh",
          width: "50%",
          backgroundSize: "cover",
          background:
            "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <VideolinkHook
          t={t}
          lang={lang}
          setLang={setLang}
          banner={banner}
          principalColor={principalColor}
          buttonColor={buttonColor}
          page={consts.mode_contact}
        />
      </Box>
    </Box>
  );
}

export default DesktopView;
