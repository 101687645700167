import Box from "@mui/material/Box";

import ListLinks from "../../components/listLinks";
import imageConfirmationAccount from "../../assets/endImage.svg";

function DesktopView({ t, setLang, lang, onBack, onFinish, contactCenterURL }) {
  return (
    <Box style={{ width: "100%", display: "flex" }}>
      <Box
        style={{
          width: "50%",
          height: "100vh",
          textAlign: "center",
          padding: 16,
          overflowY: "scroll",
          paddingTop: 24,
        }}
      >
        <ListLinks
          t={t}
          lang={lang}
          setLang={setLang}
          onBack={onBack}
          onFinish={onFinish}
          contactCenterURL={contactCenterURL}
        />
      </Box>
      <Box
        style={{
          height: "100vh",
          width: "50%",
          backgroundSize: "cover",
          background:
            "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={imageConfirmationAccount} alt="" style={{ width: "75%" }} />
      </Box>
    </Box>
  );
}

export default DesktopView;
