import { useState, useEffect } from "react";
import Box from "@mui/material/Box";

import backgroundRegistry from "../../assets/backgroundRegistry.png";
import imageRegistry from "../../assets/imageRegistry.svg";
import RegistryForm from "../../components/registryForm";
import ChangePasswordForm from "../../components/changePasswordForm";

function DesktopView({
  t,
  setLang,
  lang,
  isMobile,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  isChecked,
  setIsChecked,
  onConfirmAccount,
}) {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    setHeight(window.innerHeight);
    console.log(height);
  }, [window.innerHeight]);

  return (
    <Box style={{ width: "100%", display: "flex" }}>
      <Box
        style={{
          width: "50%",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          padding: 16,
          // paddingTop: 70.1,
          // paddingBottom: window.innerHeight <= 800 && 130,
          overflow: "auto",
        }}
      >
        <ChangePasswordForm
          t={t}
          isMobile={isMobile}
          isConfirmAccount={true}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          onConfirmAccount={onConfirmAccount}
        />
      </Box>
      <Box
        style={{
          height: "100vh",
          width: "50%",
          backgroundSize: "cover",
          background:
            "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={imageRegistry} alt="" style={{ width: "75%" }} />
      </Box>
    </Box>
  );
}

export default DesktopView;
