import axios from "axios";
import { API_BASE_URL, TOKEN_KEY } from "../config";

const requestHandler = (config) => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) config.headers.Authorization = `videolink ${token}`;

  return config;
};
const responseHandler = (response) => {
  const { data: dataResponse, status } = response;
  if (status === 200) {
    return dataResponse;
  } else {
    return Promise.reject({ dataResponse, status });
  }
};

const baseURL = API_BASE_URL;

const axiosInstance = axios.create({
  baseURL,
  validateStatus() {
    return true;
  },
});

axiosInstance.interceptors.request.use(requestHandler, (error) =>
  Promise.reject(error)
);

axiosInstance.interceptors.response.use(responseHandler, (error) =>
  Promise.reject(error)
);

export default axiosInstance;
