import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import useMediaQuery from "@mui/material/useMediaQuery";

import i18n from "../i18n";
import MobileView from "../views/links/mobile";
import DesktopView from "../views/links/desktop";
import service from "../service";
import { EasyContext } from "../context";

function Links() {
  const theme = useTheme();
  const navigate = useNavigate();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();
  const { state, setConfigRoles } = useContext(EasyContext);
  const [lang, setLang] = useState(state.user ? state.user.lang : "en");
  const [contactCenterURL, setContactCenterURL] = useState();

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    console.log(localStorage.getItem("isLink"));
    //if (!localStorage.getItem("isLink")) navigate("/");
  }, []);

  useEffect(() => {
    getApp();
  }, []);

  return !mobileBreakpoint ? (
    <MobileView
      t={t}
      lang={lang}
      setLang={setLang}
      onBack={onBack}
      onFinish={onFinish}
      contactCenterURL={contactCenterURL}
    />
  ) : (
    <DesktopView
      t={t}
      lang={lang}
      setLang={setLang}
      onBack={onBack}
      onFinish={onFinish}
      contactCenterURL={contactCenterURL}
    />
  );

  function onBack() {
    return navigate("/roles/config");
  }

  function onFinish() {
    navigate("/end");
  }

  async function getApp() {
    try {
      const result = await service.getContactCenterURL(state.user.company._id);

      setContactCenterURL(result.url);
    } catch (err) {
      console.log(err);
    }
  }
}

export default Links;
