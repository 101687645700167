import { useEffect } from "react";
import { EasyProvider } from "./context/provider";
import { AppRouter } from "./router";

function App() {
  
  useEffect(() => {
    return () => {
      if (window.performance.navigation.type === 1) {
        return;
      } else {
        localStorage.clear();
      }
    };
  }, []);

  return (
    <EasyProvider>
      <AppRouter />
    </EasyProvider>
  );
}

export default App;
