import { types } from "../types";

export const EasyReducer = (state = {}, action) => {
  switch (action.type) {
    case types.verify_account:
      return {
        ...state,
        user: action.payload,
      };
    case types.config_contact:
      return {
        ...state,
        banner: action.payload.banner,
        principalColor: action.payload.principalColor,
        buttonColor: action.payload.buttonColor,
      };
    case types.config_attentions:
      return {
        ...state,
        title: action.payload.title,
        description: action.payload.description,
        buttonTitle: action.payload.buttonTitle,
        buttonAttentionsColor: action.payload.buttonAttentionsColor,
        attentions: action.payload.attentions,
        iconColor: action.payload.iconColor,
      };
    case types.config_roles:
      return {
        ...state,
        roles: action.payload.roles,
        companyType: action.payload.companyType,
      };
    case types.config_lang:
      return {
        ...state,
        lang: action.payload.lang,
      };
    default:
      return state;
  }
};
