import Box from "@mui/material/Box";
import Logo_videolink from "../assets/Logo_videolink.png";

function HeaderConfig({
  t,
  isMobile,
  isConfirmAccount,
  isFinish,
  showTour,
  pae,
  page,
  height,
}) {
  return (
    <>
      <Box
        style={{
          marginTop: !isMobile && 32,
          marginBottom: !isMobile && 49.82,
          textAlign: "center",
        }}
      >
        <img
          src={Logo_videolink}
          alt="logo"
          style={{ width: !isMobile ? 363 : "90%", maxWidth: 400 }}
        />
      </Box>

      <Box
        style={{
          fontFamily: "Rubik",
          fontSize: !isMobile ? 40 : height >= 1024 ? 32 : 26,
          fontWeight: 400,
          textAlign: "center",
          marginTop: isConfirmAccount ? isMobile && 40 : isMobile ? 8 : "",
        }}
      >
        {isFinish
          ? t("links.congrats")
          : isConfirmAccount
          ? "Generar contraseña"
          : t("contact.center.welcome")}
      </Box>
      {isFinish ? (
        <Box
          style={{
            fontFamily: "Rubik",
            fontSize: !isMobile ? 14 : height >= 1024 ? 24 : 12,
            fontWeight: 600,
            paddingRight: 8,
            marginBottom: 12,
            textAlign: "center",
            marginTop: isMobile && 8,
          }}
        >
          {t("links.already.config")}
          {/* Ya configuraste todo tu Contact Center */}
        </Box>
      ) : (
        !isConfirmAccount && (
          <Box
            style={{
              fontFamily: "Rubik",
              fontSize: !isMobile ? 14 : height >= 1024 ? 24 : 12,
              fontWeight: 600,
              paddingRight: 8,
              marginBottom: 12,
              textAlign: "center",
              marginTop: isMobile && 8,
            }}
          >
            {page !== "roles" ? t("contact.center.init") : t("roles.add.user")}
          </Box>
        )
      )}
      {isFinish
        ? ""
        : !isConfirmAccount && (
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Box
                style={{
                  fontFamily: "Rubik",
                  fontSize: !isMobile ? 14 : height >= 1024 ? 24 : 12,
                  paddingRight: 8,
                  fontWeight: 600,
                }}
              >
                {t("contact.center.need.help")}
              </Box>
              <Box
                className="showTour"
                onClick={() => showTour()}
                style={{
                  fontFamily: "Rubik",
                  fontSize: !isMobile ? 14 : height >= 1024 ? 24 : 12,
                  fontWeight: "bold",
                  color: "#EB3E67",
                  fontStyle: "italic",
                }}
              >
                {t("contact.center.show.steps")}
              </Box>
            </Box>
          )}
    </>
  );
}

export default HeaderConfig;
