import Box from "@mui/material/Box";

import backgroundRegistry from "../../assets/backgroundRegistry.png";
import AttentionsForm from "../../components/attentionsForm";
import VideolinkHook from "../../components/videolinkHook";
import { consts } from "../../consts";

function DesktopView({
  t,
  lang,
  setLang,
  banner,
  principalColor,
  buttonColor,
  hookTitle,
  setHookTitle,
  hookSubtitle,
  setHookSubtitle,
  buttonText,
  setButtonText,
  attentions,
  isOpenButtonColorPicker,
  setIsOpenButtonColorPicker,
  setButtonColorAttentions,
  buttonColorAttentions,
  onDeleteAttention,
  onAddAttention,
  onChangeColor,
  selectedAttention,
  setSelectedAttention,
  onHandleChangeTitle,
  onHandleChangeDescription,
  updateAttentions,
  onBack,
  isLoading,
  onChangeIcon,
  iconColor,
  showTour,
}) {
  return (
    <Box style={{ width: "100%", display: "flex", overflow: "hidden" }}>
      <Box
        style={{
          width: "50%",
          height: "100vh",
          textAlign: "center",
          padding: 16,
          overflowY: "scroll",
          paddingTop: 24,
          paddingBottom: 24,
        }}
      >
        <AttentionsForm
          t={t}
          hookTitle={hookTitle}
          setHookTitle={setHookTitle}
          hookSubtitle={hookSubtitle}
          setHookSubtitle={setHookSubtitle}
          buttonText={buttonText}
          setButtonText={setButtonText}
          attentions={attentions}
          isOpenButtonColorPicker={isOpenButtonColorPicker}
          setIsOpenButtonColorPicker={setIsOpenButtonColorPicker}
          setButtonColorAttentions={setButtonColorAttentions}
          buttonColorAttentions={buttonColorAttentions}
          onAddAttention={onAddAttention}
          onChangeColor={onChangeColor}
          selectedAttention={selectedAttention}
          setSelectedAttention={setSelectedAttention}
          onHandleChangeTitle={onHandleChangeTitle}
          onHandleChangeDescription={onHandleChangeDescription}
          updateAttentions={updateAttentions}
          onBack={onBack}
          isLoading={isLoading}
          onChangeIcon={onChangeIcon}
          iconColor={iconColor}
          onDeleteAttention={onDeleteAttention}
          showTour={showTour}
        />
      </Box>
      <Box
        style={{
          height: "100vh",
          width: "50%",
          backgroundSize: "cover",
          background:
            "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <VideolinkHook
          t={t}
          lang={lang}
          setLang={setLang}
          banner={banner}
          principalColor={principalColor}
          buttonColor={buttonColor}
          page={consts.mode_attentions}
          hookTitle={hookTitle}
          hookSubtitle={hookSubtitle}
          attentions={attentions}
          buttonText={buttonText}
          buttonColorAttentions={buttonColorAttentions}
          onDeleteAttention={onDeleteAttention}
        />
      </Box>
    </Box>
  );
}

export default DesktopView;
