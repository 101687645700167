import Box from "@mui/material/Box";

import imageRoles from "../../assets/rolesImage.svg";
import DialogConfirmationRoles from "../../components/dialogConfirmationRoles";
import ListRoles from "../../components/listRoles";
import RolesForm from "../../components/rolesForm";

function DesktopView({
  t,
  setLang,
  lang,
  onBack,
  adminName,
  setAdminName,
  supervisorName,
  setSupervisorName,
  agentName,
  setAgentName,
  adminEmail,
  setAdminEmail,
  supervisorEmail,
  setSupervisorEmail,
  agentEmail,
  setAgentEmail,
  roles,
  addRol,
  deleteRol,
  onCreateUsers,
  isSmall,
  setIsSmall,
  agentLastName,
  setAgentLastName,
  supervisorLastName,
  setSupervisorLastName,
  adminLastName,
  setAdminLastName,
  isLoading,
  open,
  setOpen,
  user,
  showTour,
}) {
  return (
    <Box style={{ width: "100%", display: "flex" }}>
      <Box
        style={{
          width: "50%",
          height: "100vh",
          textAlign: "center",
          padding: 16,
          overflowY: "scroll",
          paddingTop: 24,
          paddingBottom: 24,
        }}
      >
        <RolesForm
          t={t}
          lang={lang}
          setLang={setLang}
          onBack={onBack}
          adminName={adminName}
          setAdminName={setAdminName}
          supervisorName={supervisorName}
          setSupervisorName={setSupervisorName}
          agentName={agentName}
          setAgentName={setAgentName}
          adminEmail={adminEmail}
          setAdminEmail={setAdminEmail}
          supervisorEmail={supervisorEmail}
          setSupervisorEmail={setSupervisorEmail}
          agentEmail={agentEmail}
          setAgentEmail={setAgentEmail}
          roles={roles}
          addRol={addRol}
          onCreateUsers={onCreateUsers}
          isSmall={isSmall}
          setIsSmall={setIsSmall}
          agentLastName={agentLastName}
          setAgentLastName={setAgentLastName}
          supervisorLastName={supervisorLastName}
          setSupervisorLastName={setSupervisorLastName}
          adminLastName={adminLastName}
          setAdminLastName={setAdminLastName}
          isLoading={isLoading}
          setOpen={setOpen}
          user={user}
          showTour={showTour}
        />
      </Box>
      <Box
        style={{
          height: "100vh",
          width: "50%",
          backgroundSize: "cover",
          background:
            "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ListRoles
          roles={roles}
          deleteRol={deleteRol}
          isSmall={isSmall}
          user={user}
          t={t}
        />
      </Box>
      <DialogConfirmationRoles
        open={open}
        setOpen={setOpen}
        onCreateUsers={onCreateUsers}
        isLoading={isLoading}
        t={t}
      />
    </Box>
  );
}

export default DesktopView;
