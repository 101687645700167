import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";

import imageConfirmation from "../assets/confirmRoles.svg";
import close from "../assets/close.svg";

function DialogConfirmationRoles({
  open,
  setOpen,
  isLoading,
  onCreateUsers,
  t,
}) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ border: "1px solid #AFB8CD", borderRadius: 10 }}
    >
      <DialogTitle id="alert-dialog-title" style={{ marginLeft: "auto" }}>
        <img
          src={close}
          alt=""
          style={{ width: 12, height: 12 }}
          className="delete-attention"
          onClick={() => setOpen(false)}
        />
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box style={{ textAlign: "center" }}>
            <img src={imageConfirmation} alt="" style={{ width: "100%" }} />
          </Box>
          <Box
            style={{
              textAlign: "center",
            }}
          >
            <h3
              style={{
                fontFamily: "Rubik",
                fontWeight: "bold",
              }}
            >
              {t("roles.send.string")}
            </h3>
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              textAlign: "justify",
            }}
          >
            {t("roles.verify.string")}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Box
          style={{ display: "flex", width: "100%", justifyContent: "center" }}
        >
          <Button
            fullWidth
            style={{
              textTransform: "inherit",
              borderRadius: 20,
              fontSize: 14,
              fontWeight: 700,
              boxShadow: "none",
              backgroundColor: "#FFFFFF",
              color: "#52596B",
              border: "1px solid #52596B",
              height: 32,
              marginBottom: 8,
              fontFamily: "Rubik",
              width: "33.33%",
              marginRight: 12,
            }}
            variant="contained"
            onClick={() => setOpen(false)}
          >
            {t("roles.cancel.string")}
          </Button>
          <LoadingButton
            loadingPosition="start"
            loading={isLoading}
            onClick={() => onCreateUsers()}
            fullWidth
            style={{
              textTransform: "inherit",
              borderRadius: 20,
              fontSize: 14,
              fontWeight: 700,
              boxShadow: "none",
              height: 32,
              color: "#FFFFFF",
              backgroundColor: isLoading ? "#AFB8CD" : "#EB3E67",
              marginBottom: 8,
              width: "33.33%",
            }}
            variant="contained"
            size="small"
          >
            {t("roles.send.string")}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default DialogConfirmationRoles;
