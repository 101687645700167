import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LoadingButton from "@mui/lab/LoadingButton";

import InfoIcon from "@mui/icons-material/Info";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { styled } from "@mui/material/styles";

import StepperVideolink from "./stepper";
import HeaderConfig from "./hedaerConfig";
import addImage from "../assets/addImage.png";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#17191E !important",
    width: "auto !important",
    color: "#FFFFFF !important",
    fontSize: 14,
    borderRadius: 5,
    boxShadow: "none",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#17191E !important",
  },
}));

function ContactCenterForm({
  t,
  isMobile,
  banner,
  handleChageBanner,
  principalColor,
  setPrincipalColor,
  buttonColor,
  setButtonColor,
  onUpdatePreferences,
  isLoading,
  setIsShowHook,
  showTour,
  height,
  width,
}) {
  const activeStep = 0;
  return (
    <Box
      style={{
        maxWidth: !isMobile ? 750 : height >= 1025 ? 895 : 750,
        background: isMobile && "#FFFFFF",
        margin: "auto",
        paddingBottom: 8,
        paddingTop: isMobile && 16,
        borderTopLeftRadius: isMobile && 10,
        borderTopRightRadius: isMobile && 10,
        paddingLeft: height < 1025 && width > 750 && 60,
        paddingRight: height < 1025 && width > 750 && 60,
      }}
    >
      <HeaderConfig
        t={t}
        isMobile={isMobile}
        showTour={showTour}
        height={height}
      />
      <StepperVideolink
        activeStep={activeStep}
        isMobile={isMobile}
        height={height}
      />
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: !isMobile ? 16 : 8,
          padding: isMobile && 8,
          paddingLeft: isMobile && 24,
        }}
      >
        <Box
          style={{
            fontFamily: "Rubik",
            fontSize: !isMobile ? 20 : height >= 1025 ? 43.51 : 16,
            fontWeight: 600,
            paddingLeft: !isMobile && 42,
          }}
        >
          Banner
        </Box>
        <Box style={{ paddingTop: 8, paddingLeft: 4 }}>
          <LightTooltip
            title={t("contact.center.info")}
            arrow
            placement="right"
          >
            <InfoIcon
              color="primary"
              style={{ width: !isMobile ? 16 : height >= 1025 ? 42 : 16 }}
            />
          </LightTooltip>
        </Box>
      </Box>
      <Box
        className="contact-center"
        style={{
          backgroundColor: "#FBFBFF",
          // borderRadius: 10,
          padding: 24,
          border: "1px solid #AFB8CD",
          maxHeight: !isMobile && 299,
        }}
      >
        {!isMobile ? (
          <Box style={{ display: !isMobile && "flex", width: "100%" }}>
            <Box
              style={{
                width: "100%",
                // display: "flex",
                // alignItems: "center",
              }}
              className="banner-step"
            >
              <Box
                style={{
                  fontSize: 14,
                  paddingLeft: 8,
                  fontWeight: 600,
                  fontFamily: "Rubik",
                  display: "flex",
                }}
              >
                {t("contact.center.upload.banner")}
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                  borderRadius: 20,
                  padding: 8,
                  marginTop: 8,
                  background: "#FFFFFF",
                  maxHeight: 200,
                  cursor: "pointer",
                }}
                // className="color-picket-background"
                component="label"
              >
                <img
                  src={!banner ? addImage : banner.image}
                  alt=""
                  style={{ width: banner && 100 }}
                />
                <input
                  type="file"
                  hidden
                  onChange={(event) => handleChageBanner(event)}
                  id="uploadBanner"
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 16,
                  width: "100%",
                }}
              >
                <Button
                  disabled={isLoading}
                  component="label"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{
                    borderRadius: 20,
                    textTransform: "capitalize",
                    color: "#FFFFFF",
                    marginBottom: 8,
                    fontWeight: 700,
                    width: "50%",
                    height: 32,
                    boxShadow: "none",
                    fontFamily: "Rubik",
                  }}
                  id="load-banner"
                >
                  {t("contact.center.load")}
                  <input
                    type="file"
                    hidden
                    onChange={(event) => handleChageBanner(event)}
                    id="uploadBanner"
                  />
                </Button>
              </Box>
            </Box>
            <Box
              style={{
                width: "100%",
                alignItems: "center",
                marginTop: isMobile && 16,
              }}
            >
              <Box
                style={{
                  width: "100%",
                  marginTop: 8,
                }}
              >
                <Box
                  style={{
                    fontSize: 14,
                    paddingLeft: 8,
                    fontWeight: 600,
                    fontFamily: "Rubik",
                    display: "flex",
                    marginBottom: 27,
                    justifyContent: "center",
                  }}
                >
                  {t("contact.center.color.principal")}
                </Box>
                <Box
                  style={{
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: 20,
                    padding: 8,
                    marginTop: 8,
                    width: "75%",
                    paddingLeft: 12,
                    paddingRight: 12,
                    height: 30,
                  }}
                  className="color-picket-background"
                  onClick={() =>
                    document.getElementById("colorBackground").click()
                  }
                >
                  <input
                    type="color"
                    id="colorBackground"
                    className="colorPicker-vl"
                    value={principalColor ? principalColor : "#AFB8CD"}
                    onChange={(event) => setPrincipalColor(event.target.value)}
                  />

                  <Box style={{ width: "70%", marginLeft: 12 }}>
                    {principalColor ? principalColor : "#AFB8CD"}
                  </Box>
                  <Box
                    style={{ width: "15%" }}
                    onClick={() =>
                      document.getElementById("colorBackground").click()
                    }
                  >
                    <KeyboardArrowDownIcon />
                  </Box>
                </Box>
                <Box
                  style={{
                    fontSize: 14,
                    paddingLeft: 8,
                    fontWeight: 600,
                    fontFamily: "Rubik",
                    display: "flex",
                    marginBottom: 27,
                    justifyContent: "center",
                    marginTop: 60,
                  }}
                >
                  {t("contact.center.button.color")}
                </Box>
                <Box
                  style={{
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: 20,
                    padding: 8,
                    marginTop: 8,
                    width: "75%",
                    paddingLeft: 12,
                    paddingRight: 12,
                    height: 30,
                  }}
                  className="color-picket-backgroun2 color-picket-background"
                  onClick={() =>
                    document
                      .getElementById("colorBackgroundButtonContact")
                      .click()
                  }
                >
                  <input
                    type="color"
                    value={buttonColor ? buttonColor : "#AFB8CD"}
                    id="colorBackgroundButtonContact"
                    className="colorPicker-vl"
                    onChange={(event) => setButtonColor(event.target.value)}
                  />
                  <Box style={{ width: "70%", marginLeft: 12 }}>
                    {buttonColor ? buttonColor : "#AFB8CD"}
                  </Box>
                  <Box style={{ width: "15%" }}>
                    <KeyboardArrowDownIcon />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            style={{ display: !isMobile && "flex", width: "100%" }}
            className="banner-step"
          >
            <Box
              style={{
                fontSize: height >= 1025 ? 30.74 : 14,
                paddingLeft: 8,
                fontWeight: 600,
                fontFamiy: "Rubik",
                display: "flex",
              }}
            >
              {t("contact.center.upload.banner")}
            </Box>
            <Box
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                minHeight: height >= 1025 && 100,
                borderRadius: 20,
                padding: 8,
                marginTop: 8,
              }}
              component="label"
            >
              <img
                src={!banner ? addImage : banner.image}
                alt=""
                style={{
                  width: banner && "100%",
                  maxHeight: 300,
                  objectFit: "contain",
                }}
              />
              <input
                type="file"
                hidden
                onChange={(event) => handleChageBanner(event)}
                id="uploadBanner"
              />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 12,
                width: "100%",
              }}
            >
              <Button
                disabled={isLoading}
                component="label"
                fullWidth
                variant="contained"
                color="primary"
                style={{
                  borderRadius: height >= 1025 ? 40 : 20,
                  textTransform: "capitalize",
                  color: "#FFFFFF",
                  marginBottom: 8,
                  fontWeight: 700,
                  width: "50%",
                  height: height >= 1025 ? 61 : 32,
                  boxShadow: "none",
                  fontFamily: "Rubik",
                  fontSize: height >= 1025 ? 30.74 : 14,
                }}
                id="load-banner"
              >
                Cargar
                <input
                  type="file"
                  hidden
                  onChange={(event) => handleChageBanner(event)}
                  id="uploadBanner"
                />
              </Button>
            </Box>
          </Box>
        )}

        {isMobile && (
          <Box
            style={{
              display: !isMobile && "flex",
              width: "100%",
            }}
          >
            <Box
              style={{
                fontSize: height >= 1025 ? 30.74 : 14,
                paddingLeft: 8,
                fontWeight: 600,
                fontFamiy: "Rubik",
                display: "flex",
                marginTop: height >= 1025 ? 60 : 8,
              }}
            >
              {t("contact.center.banner.principal.color")}
            </Box>
            <Box
              style={{
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: height >= 1025 ? 40 : 20,
                padding: 8,
                marginTop: height >= 1025 ? 60 : 8,
                width: height >= 1024 || width > height ? "55%" : "75%",
                paddingLeft: 12,
                paddingRight: 12,
                height: height >= 1025 ? 60 : 35,
                maxWidth: isMobile && height >= 1025 && 450,
              }}
              className="color-picket-background"
              onClick={() => document.getElementById("colorBackground").click()}
            >
              <input
                type="color"
                id="colorBackground"
                className="colorPicker-vl"
                value={principalColor ? principalColor : "#AFB8CD"}
                onChange={(event) => setPrincipalColor(event.target.value)}
              />

              <Box
                style={{
                  width: "70%",
                  marginLeft: 12,
                  fontSize: height >= 1025 && 25,
                  textAlign: "center",
                }}
              >
                {principalColor ? principalColor : "#AFB8CD"}
              </Box>
              <Box
                style={{ width: "15%" }}
                onClick={() =>
                  document.getElementById("colorBackground").click()
                }
              >
                <KeyboardArrowDownIcon />
              </Box>
            </Box>
          </Box>
        )}
        {!isMobile ? (
          <Box
            style={{
              display: "flex",
              width: "100%",
              marginTop: 32,
              marginLeft: -5,
            }}
          ></Box>
        ) : (
          <Box
            style={{
              display: !isMobile && "flex",
              width: "100%",
            }}
          >
            <Box
              style={{
                fontSize: height >= 1025 ? 30.74 : 14,
                paddingLeft: 8,
                fontWeight: 600,
                fontFamiy: "Rubik",
                display: "flex",
                marginBottom: 8,
                marginTop: height >= 1025 ? 60 : 8,
              }}
            >
              {t("contact.center.button.color")}
            </Box>
            <Box
              style={{
                margin: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: height >= 1025 ? 40 : 20,
                padding: 8,
                marginTop: height >= 1025 ? 60 : 8,
                width: height >= 1024 || width > height ? "55%" : "75%",
                paddingLeft: 12,
                paddingRight: 12,
                height: height >= 1025 ? 60 : 35,
                maxWidth: isMobile && height >= 1025 && 450,
              }}
              className="color-picket-backgroun2"
              onClick={() =>
                document.getElementById("colorBackgroundButtonContact").click()
              }
            >
              <input
                type="color"
                value={buttonColor ? buttonColor : "#AFB8CD"}
                id="colorBackgroundButtonContact"
                className="colorPicker-vl"
                onChange={(event) => setButtonColor(event.target.value)}
              />
              <Box
                style={{
                  width: "70%",
                  marginLeft: 12,
                  fontSize: height >= 1025 && 25,
                  textAlign: "center",
                }}
              >
                {buttonColor ? buttonColor : "#AFB8CD"}
              </Box>
              <Box style={{ width: "15%" }}>
                <KeyboardArrowDownIcon />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        style={{
          width: !isMobile ? "100%" : "100%",
          display: !isMobile && "flex",
          // position: isMobile && "absolute",
          bottom: isMobile && 0,
          justifyContent: "end",
          marginTop: 16,
        }}
      >
        {isMobile ? (
          <Box style={{ textAlign: "center" }}>
            <Box
              style={{ textAlign: "center", marginBottom: 4, marginTop: 22 }}
            >
              <Button
                onClick={() => setIsShowHook(true)}
                style={{
                  textTransform: "inherit",
                  borderRadius: height >= 1025 ? 40 : 20,
                  width: height >= 1024 ? "85%" : "100%",
                  fontSize: height >= 1025 ? 27 : 14,
                  fontWeight: 700,
                  boxShadow: "none",
                  backgroundColor: "#FFFFFF",
                  color: "#52596B",
                  border: "1px solid #52596B",
                  height: height >= 1025 ? 60 : 32,
                  marginBottom: 8,
                  fontFamily: "Rubik",
                }}
                id="previewId"
                variant="contained"
                className="contact-senter-step"
              >
                Visualizar
              </Button>
            </Box>
            <LoadingButton
              loadingPosition="start"
              loading={isLoading}
              // fullWidth
              variant="contained"
              disabled={!principalColor || !buttonColor || !banner}
              color="primary"
              style={{
                borderRadius: height >= 1025 ? 40 : 20,
                textTransform: "capitalize",
                color: "#FFFFFF",
                marginBottom: 8,
                fontWeight: 700,
                width: height >= 1024 ? "85%" : "100%",
                height: height >= 1025 ? 60 : 32,
                boxShadow: "none",
                backgroundColor:
                  !principalColor || !buttonColor || !banner ? "#D6D8E8" : "",
                fontSize: height >= 1025 ? 27 : 14,
              }}
              onClick={() => {
                onUpdatePreferences(banner, principalColor, buttonColor);
              }}
            >
              {t("button.next.step")}
            </LoadingButton>
          </Box>
        ) : (
          <>
            <LoadingButton
              id="button-next"
              loadingPosition="start"
              loading={isLoading}
              fullWidth
              variant="contained"
              disabled={!principalColor || !buttonColor || !banner}
              color="primary"
              style={{
                fontFamily: "Rubik",
                borderRadius: 20,
                textTransform: "capitalize",
                color: "#FFFFFF",
                marginBottom: 8,
                fontWeight: 700,
                width: "33%",
                height: 33,
                boxShadow: "none",
                backgroundColor:
                  !principalColor || !buttonColor || !banner || isLoading
                    ? "#D6D8E8"
                    : "",
              }}
              onClick={() => {
                onUpdatePreferences(banner, principalColor, buttonColor);
              }}
            >
              {t("button.next.step")}
            </LoadingButton>
          </>
        )}
      </Box>
    </Box>
  );
}

export default ContactCenterForm;
