import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ConfirmationAccount from "./pages/confirmationAccount";
import Registry from "./pages/registry";
import ReadEmailNotification from "./pages/readEmailNorification";
import ContactCenterConfig from "./pages/contactCenterConfig";
import AttentionsConfig from "./pages/attentionsConfig";
import NotFound from "./pages/notFound";
import RolesConfig from "./pages/rolesConfig";
import Links from "./pages/links";
import End from "./pages/end";
import ChangePassword from "./pages/changePassword";
import Conditions from "./pages/conditions";

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Registry />} />
        <Route path="/confirmation/:token" element={<ConfirmationAccount />} />
        <Route path="/change_password/:token" element={<ChangePassword />} />
        <Route path="/notification" element={<ReadEmailNotification />} />
        <Route path="/contact/config" element={<ContactCenterConfig />} />
        <Route path="/attentions/config" element={<AttentionsConfig />} />
        <Route path="/roles/config" element={<RolesConfig />} />
        <Route path="/links" element={<Links />} />
        <Route path="/end" element={<End />} />
        <Route path="/easy/404" element={<NotFound />} />
        <Route path="/conditions" element={<Conditions />} />
        <Route path="*" element={<Navigate to="/easy/404" />} />
      </Routes>
    </BrowserRouter>
  );
};
