import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Box from "@mui/material/Box";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#EA345F",
      color: "#EA345F",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#EA345F",
      color: "#EA345F",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));
const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: "#EA345F",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#EA345F",
  },
  "& .QontoStepIcon-circle": {
    color: "#AFB8CD",
  },
}));

const QontoStepIcon = (props) => {
  return (
    <QontoStepIconRoot
      ownerState={{ active: props.active }}
      className={props.className}
    >
      <ArrowForwardIosIcon
        className={
          props.active || props.completed
            ? "QontoStepIcon-completedIcon"
            : "QontoStepIcon-circle"
        }
      />
    </QontoStepIconRoot>
  );
};

const steps = [
  "Select campaign settings",
  "Create an ad group",
  "Create an ad",
  "Create all",
];

function StepperVideolink({ activeStep, isMobile, height }) {
  return (
    <Box style={{ maxWidth: 650, margin: "auto" }} className="stepper">
      <Stepper
        style={{ marginTop: 14, margiBottom: 14, width: "100%" }}
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon} />
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

export default StepperVideolink;
