import { useState, useEffect } from "react";
import { Box, Dialog, Backdrop } from "@mui/material";
import { styled } from "@mui/material/styles";

import Logo_videolink from "../../assets/Logo_videolink.png";
import conditions from "../../assets/conditions.svg";

function MobileView({ t }) {
  const [isOpenDialog] = useState(true);
  const [height, setHeight] = useState(window.innerHeight);
  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Box style={{ height: "100vh" }}>
      <>
        <Box
          style={{
            height: isOpenDialog ? (height >= 1180 ? "15vh" : "25vh") : "45vh",
            width: "100%",
            background:
              "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
            backgroundSize: "cover",
            display: "flex",
            padding: 12,
          }}
        ></Box>
        <Dialog
          fullScreen
          open={true}
          PaperProps={{
            style: {
              borderTopLeftRadius: 10,
              height: isOpenDialog ? "98%" : "90%",
              position: "fixed",
              bottom: 0,
              width: "95%",
              borderTopRightRadius: 10,
              paddingLeft: 16,
              paddingRight: 16,
            },
          }}
          BackdropComponent={styled(Backdrop, {
            name: "MuiModal",
            slot: "Backdrop",
            overridesResolver: (props, styles) => {
              return styles.backdrop;
            },
          })({
            zIndex: -1,
            position: "relative",
          })}
        >
          <Box
            style={{
              // display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // margin: "auto",
              marginTop: 24,
            }}
          >
            <Box>
              <Box
                style={{
                  textAlign: "center",
                }}
              >
                <img src={Logo_videolink} alt="logo" style={{ width: "90%" }} />
              </Box>
              <Box
                style={{
                  fontFamily: "Rubik",
                  fontSize: 24,
                  fontWeight: 600,
                  textAlign: "center",
                  // marginTop: 59.4,
                  marginTop: 26.66,
                }}
              >
                ¡Estos son nuestros términos!
              </Box>
              <Box
                style={{
                  fontFamily: "Rubik",
                  fontSize: 24,
                  fontWeight: 600,
                  textAlign: "justify",
                  marginTop: 59.4,
                  marginBottom: 26.66,
                }}
              >
                ¿Quiénes somos?{" "}
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 18,
                  fontFamily: "Rubik",
                }}
              >
                Nuestra misión es hacer que comunicarse con una empresa sea tan
                fácil como hablar con un amigo, y eso comienza aquí mismo.
                Revise estos Términos y condiciones (denominados estos
                "Términos") detenidamente, ya que forman parte del acuerdo legal
                entre usted y nosotros con respecto a los servicios que
                ofrecemos. En estos Términos, el “Acuerdo” de procesamiento de
                datos, la documentación, los Términos específicos del producto y
                los Formularios de pedido aplicables (como se define a
                continuación) como el "Acuerdo". El Acuerdo establece los
                términos completos del acuerdo legal entre usted y nosotros en
                relación con los Servicios que ofrecemos.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Los términos "usted", "su" o "Cliente" se refieren a usted, y
                los términos "nosotros", "nuestro" o "Videolink" se refieren a
                la entidad contratante de Videolink enumerada en la Sección 15
                (Entidad contratante), a menos que se indique lo contrario en su
                Formulario de pedido. Usted o Videolink también pueden ser
                referidos individualmente como una "Parte" y juntos como
                "Partes" en estos Términos. Un “afiliado” significa cualquier
                entidad que directa o indirectamente controle, o sea controlada
                por, o esté bajo control común con la Parte especificada. A los
                efectos de esta definición, "control" significa la propiedad
                directa o indirecta de más del cincuenta por ciento (50%) de los
                derechos de voto de la entidad en cuestión o el poder de dirigir
                la gestión y las políticas de la entidad en cuestión.
              </Box>
              <Box
                style={{
                  fontFamily: "Rubik",
                  fontSize: 24,
                  fontWeight: 600,
                  textAlign: "justify",
                  marginTop: 59.4,
                  marginBottom: 26.66,
                }}
              >
                Términos generales y condiciones del servicio
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Cuando usted abre una cuenta con nosotros, entendemos que acepta
                los términos y condiciones que a continuación detallamos. Para
                crear una cuenta y utilizar nuestros servicios, usted debe ser
                legalmente capaz, de acuerdo con la legislación de su país, de
                representar a la empresa o negocio que contrata nuestros
                servicios.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Creación de la cuenta: Para ello solicitaremos nos proporcione :
                una dirección de correo electrónico y un número telefónico.
                Entendemos que usted se obliga a proporcionar datos correctos ,
                actualizados y completos, durante la duración del uso de
                nuestros servicios. Sus afiliados podrán utilizar nuestros
                servicios, para ello será necesario que creen su propia cuenta y
                acepte nuestros términos y condiciones , en los casos anteriores
                las partes se comprometen a no utilizar los servicios que presta
                VIDEOLINK infringiendo normativa legal de cualquier índole (
                civil, mercantil, penal y cualquier otra norma o conjunto de
                normas) en cuyo caso el afiliado principal responderá también
                ante las autoridades judiciales o administrativas.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Cambios en la Cuenta: VIDEOLINK, puede cambiar en cualquier
                momento las condiciones y términos de uso, será suficiente con
                la publicación en nuestro sitio web y / o enviando un correo
                electrónico a la última dirección de correo electrónico que
                usted nos suministró. Igualmente, podemos cambiar las tarifas
                mensuales en cualquier momento mediante la publicación de los
                nuevos planes de precios en el sitio web y /o enviándole una
                notificación por correo electrónico.{" "}
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                La cuenta y su contraseña: El CLIENTE de VIDEOLINK, es
                responsable de mantener el nombre y la contraseña de su cuenta
                confidenciales, También es responsable de cualquier cuenta a la
                que tenga acceso, independientemente de que autorice o no el
                uso. EL CLIENTE deberá informar de manera inmediata a VIDEOLINK
                de cualquier uso no autorizado de sus cuentas, como consecuencia
                de ello no nos hacemos responsables de alguna pérdida debido al
                uso de contraseñas robadas o hackeadas.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                El Tratamiento de los Datos Suministrados a VIDEOLINK: Podemos
                tratar sus datos personales con los fines que se detallan a
                continuación: Fines de tratamiento fundamento de licitud. Para
                brindarle nuestros productos, servicios y propiedades digitales,
                lo que incluye procesar y completar transacciones; permitirle
                acceder a las Propiedades digitales y a nuestros servicios;
                operar, mantener y mejorar nuestras propiedades digitales y
                Servicios; comunicarnos con usted, por ejemplo, al cumplir con
                sus solicitudes de soporte o brindar actualizaciones de
                seguridad; y diagnosticar, reparar y realizar un seguimiento de
                problemas de servicio o calidad. Intereses legítimos; contrato;
                obligaciones legales. Para nuestros propios fines comerciales,
                lo que incluye llevar registros comerciales internos y presentar
                informes internos; realizar cobranzas y cumplir con funciones
                contables y comerciales similares; auditar y gestionar proyectos
                relacionados con nuestros servicios; llevar adelante una gestión
                de seguridad informática y tareas relacionadas con la
                informática, como la administración de nuestras tecnologías y
                redes; evaluar y mejorar nuestros negocios, servicios y
                propiedades digitales; e investigar y desarrollar nuevos
                productos y servicios; y procesar sus respuestas a encuestas y
                cuestionarios. Intereses legítimos, obligaciones legales: Por
                motivos legales o de seguridad, lo que incluye cumplir con
                requisitos legales; iniciar, ejercer o defenderse de acciones
                legales; proteger la seguridad y la integridad de nuestros
                bienes y los derechos de quienes interactúan con nosotros u
                otros; investigar infracciones de las políticas de conducta o
                contenidos; y detectar, prevenir y responder a incidentes de
                seguridad u otra actividad maliciosa, engañosa, fraudulenta o
                ilegal. Estos fines de seguridad también pueden implicar la
                recopilación y el tratamiento de categorías especiales de datos
                personales (es decir, datos sobre la salud), para visitas a la
                oficina y eventos cuando sea necesario por razones de salud
                pública o según lo exija la legislación aplicable. Intereses
                legítimos, obligaciones legales, interés público. Podemos
                también utilizar sus datos personales para marketing de nuestros
                productos y servicios o los de terceros, como nuestros socios
                comerciales, lo que incluye solicitar o publicar testimonios o
                comentarios sobre nuestros productos y servicios; enviarle
                comunicaciones de marketing y promociones o recomendaciones de
                productos (por correo electrónico, teléfono u otros canales en
                línea o fuera de línea) sobre nuestros servicios o los de
                terceros; facilitar su participación en un concurso o evento;
                evaluar impresiones publicitarias o participar en la
                personalización de publicidad contextual.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Cookies y Tecnologías de seguimiento: Usamos cookies y otras
                tecnologías de seguimiento y le ofrecemos la oportunidad de que
                administre esta configuración según se describe en nuestro Aviso
                sobre cookies. Algunas tecnologías de seguimiento nos permiten
                seguir la actividad de su dispositivo a lo largo del tiempo y en
                distintas disposiciones y sitios web. Si bien algunos
                navegadores han incorporado preferencias de no rastrear, también
                conocidas como "DNT" (Do Not Track), en este momento no
                cumplimos con tales indicaciones de navegadores web.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Enlaces Externos: Al interactuar con nosotros, es posible que
                encuentre enlaces a sitios externos u otros servicios en línea,
                incluidos los integrados en anuncios de terceros. No controlamos
                ni somos responsables de las políticas de privacidad y de
                recopilación de datos de dichos sitios y servicios de terceros.
                Debe consultar a dichos terceros y sus respectivos avisos de
                privacidad para obtener más información o si tiene alguna
                pregunta sobre sus prácticas.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Normas de Seguridad: Mantenemos procedimientos de seguridad
                adecuados y medidas técnicas y organizativas para proteger sus
                datos personales contra la destrucción, pérdida, divulgación,
                alteración o uso accidentales o ilícitos. Sus datos personales
                se conservarán, en general, durante el tiempo necesario para
                cumplir con los fines por los cuales los hayamos recopilado. Una
                vez que usted o su empresa hayan terminado la relación
                contractual con nosotros o hayan finalizado de otro modo su
                relación con nosotros, podremos conservar sus datos personales
                en nuestros sistemas y registros para garantizar el adecuado
                cumplimiento de las disposiciones que continúen vigentes en los
                contratos extinguidos, o para otros fines comerciales legítimos,
                como para evidenciar nuestras prácticas comerciales y
                obligaciones contractuales, para proporcionarle información
                sobre nuestros productos y servicios o para cumplir con los
                requisitos legales, fiscales o contables aplicables. Cuando no
                tengamos ninguna necesidad comercial legítima ni fundamento
                legal para tratar sus datos personales, se elimina, se anonimiza
                o se agrega o, si esto no es posible (por ejemplo, porque sus
                datos personales se han almacenado en archivos de copia de
                seguridad), almacenaremos de forma segura sus datos personales y
                los aislamos de cualquier otro tratamiento hasta que sea posible
                eliminarlos. Si desea saber más sobre los periodos de
                conservación aplicables a su circunstancia particular,
                contáctenos.
              </Box>
              <Box
                style={{
                  fontFamily: "Rubik",
                  fontSize: 24,
                  fontWeight: 600,
                  textAlign: "justify",
                  marginTop: 59.4,
                  marginBottom: 26.66,
                }}
              >
                Nuestros servicios{" "}
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Se refiere a todos los productos y servicios proporcionados por
                nosotros desde una plataforma de atención al cliente omnicanal,
                que cuenta con un portal para los agentes /especialistas,
                quienes atenderán las solicitudes desde distintos canales:
                websites, app móviles, redes sociales (WhatsApp, Instagram,
                Facebook, Telegram y otros.) Además contamos con un portal de
                gestión y analítica para los administradores. Nuestros servicios
                están orientados a los negocios, en consecuencia, no brindan
                acceso a servicios de emergencia, incluidos la policía, los
                departamentos de bomberos y servicios médicos y hospitalarios,
                ni se conectan de otra manera a los puntos de respuesta de
                seguridad pública. Para ello recomendamos utilizar canales de
                comunicación como teléfonos o móviles. Sin embargo, si se
                requiriera estos servicios de carácter sanitario o de seguridad,
                les invitamos a ponerse en contacto con nuestro equipo de ventas
                para suministrar una licencia adecuada para estos usos
                especiales.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Suspensión de Cuenta: En VIDEOLINK no estamos obligados a
                filtrar o monitorear ningún contenido o comunicación, sin
                embargo podremos suspender su cuenta si determinamos que usted o
                cualquier usuario de su Aplicación de cliente ha violado
                cualquier parte de este acuerdo incluidos nuestros Términos
                específicos del producto. También cuando el uso de los servicios
                por parte de usted o de otro usuario esté prohibido por la Ley.
                Igualmente, VIDEOLINK puede suspender su cuenta cuando su uso de
                los servicios por parte de usted o de cualquier usuario de la
                Aplicación de cliente amenace la seguridad, integridad o
                disponibilidad de los servicios o cuando la información de su
                cuenta se determine falsa o esté incompleta. Si suspendemos su
                cuenta por alguno de los particulares antes indicados o por las
                omisiones relacionadas con las tarifas y condiciones de pago, no
                seremos responsables de ningún daño, pérdidas( incluidas las de
                datos o ganancias) o cualquiera otra consecuencia que pueda
                derivarse de esta suspensión.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Mantenimiento e Inactividad: En el caso de que nuestros
                servicios dejaren de estar disponibles temporalmente debido a :
                * Fallas de hardware, cortes de energía, fallas de proveedores
                externos. *Para realizar mantenimiento, modificaciones o
                actualizaciones programadas o no programadas * Para mitigar o
                prevenir los efectos de cualquier amenaza o ataque a los
                servicios o cualquier otra red en que se basen los servicios o *
                Según se requiera por razones legales o reglamentarias. Haremos
                un esfuerzo razonable para notificar con antelación cualquier
                falta de disponibilidad de los Servicios programados, no seremos
                responsables de ningún daño, pérdida o cualquier otra
                consecuencia ocurrida como consecuencia de la falla o falta de
                disponibilidad del Servicio.{" "}
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              ></Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Responsabilidades : Cuando suscribimos un acuerdo de prestación
                de servicios, VIDEOLINK, se obliga a : * Poner nuestro Servicio
                a su disposición de acuerdo con los compromisos contraídos en el
                contrato. * Suministrar cualquier documentación técnica
                disponible para el cumplimiento del servicio a través de nuestro
                dominio web * Proporcionar el Servicio de acuerdo con las Leyes
                que le sean aplicables. Cuando el cliente acepta usar nuestro
                servicio, asume todas los términos aquí expuestos, la
                documentación requerida y las leyes aplicables.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Responsabilidades del Cliente: Al suscribirse a nuestros
                servicios entendemos que ha aceptado los términos expuestos en
                el contrato, en consecuencia usted será el único responsable de
                todo uso del servicio bajo su cuenta, incluidos actos prohibidos
                como ingeniería inversa, copia, desensamblaje, descompilación o
                modificación, copia o creación de trabajos derivados de
                cualquier parte del servicio ; de todos los actos, omisiones y
                actividades de cualquier persona que acceda o utilice cualquier
                aplicación del cliente ; de cualquier dato y otra información o
                contenido enviado por usted o para usted en virtud del acuerdo y
                procesado o almacenado por los servicios( datos de los clientes)
                y de todas las aplicaciones , dominios web, dispositivos y
                canales de comunicación que pertenecen o están controlados por
                el cliente o que pertenecen o están controlados por terceros y
                que el Cliente pone a disposición de sus usuarios reales que
                acceden, usan, interactúan o dependen del Servicio. No
                transferirá, revenderá, licenciará, ni pondrá a disposición de
                terceros los servicios excepto los que se permiten en el Acuerdo
                para que sus usuarios accedan al servicio a través de una
                aplicación de cliente. Nuestros clientes aceptan cooperar
                diligentemente con las autoridades judiciales , los proveedores
                de telecomunicaciones cuando sea requerido para ello. El Cliente
                de VIDEOLINK es responsable de evitar el acceso no autorizado o
                el uso de los Servicios a través de su cuenta y nos notificará
                de inmediato sobre dicho acceso o uso no autorizado. No seremos
                responsables de ninguna pérdida o daño que surja del uso no
                autorizado de su cuenta. El Cliente no usará nuestros servicio
                ni permitirá que usen para transmitir contenido inapropiado,
                como contenido que: no es solicitado; viole cualquier
                disposición legal, reglamentaria, de autorregulación,
                gubernamental, requisitos legales o de operadores de redes de
                telecomunicaciones o códigos de práctica; que sea pornográfico,
                abusivo, racista, obsceno, acosador, difamatorio,
                discriminatorio , ofensivo, amenazante, engañoso, inexacto, que
                sea perjudicial, incluidos entre otros los discursos de odio,
                que fomenten la violencia, la discriminación o actuaciones
                ilegales, poco éticas o inmorales. Podremos eliminar cualquier
                contenido inapropiado de los Servicios y/o suspender su acceso a
                los servicios sin previo aviso y sin ninguna responsabilidad por
                daños, pérdidas ( incluidas de datos o ganancias) o cualquier
                otra en que pudiera incurrir como resultado de la eliminación o
                suspensión.{" "}
              </Box>{" "}
              <Box
                style={{
                  fontFamily: "Rubik",
                  fontSize: 24,
                  fontWeight: 600,
                  textAlign: "justify",
                  marginTop: 59.4,
                  marginBottom: 26.66,
                }}
              >
                Tarifas y planes
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Presupuestos. A menos que se especifique explícitamente lo
                contrario, todas las ofertas de precios no son vinculantes y
                pueden ajustarse si se proporciona otra información o
                información adicional. Usted acepta pagar por los servicios que
                adquiera, incluidas las tarifas que figuran en su formulario de
                pedido o en el sitio web enlazado en esta sección. Además, el
                uso de algunos servicios puede tener limitaciones, por lo que si
                las supera y genera cargos adicionales, acepta pagarlos.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Tarifas e información de facturación. Usted se compromete a
                pagar todas las tarifas de acuerdo con las tarifas aplicables
                vigentes en cada momento El Cliente proporcionará información de
                facturación y contacto completa y precisa y nos notificará
                cualquier cambio en dicha información. El uso de los Servicios
                por parte del Cliente puede estar sujeto a ciertas limitaciones
                de uso.. Si el uso de los Servicios por parte del Cliente excede
                dichas limitaciones, el Cliente pagará la Tarifa por exceso de
                uso aplicable que figura para dicho Servicio en el Formulario de
                Pedido. Las tarifas por Excedente se considerarán parte de la
                Tarifa de Servicios y se facturará o cargarán a la tarjeta de
                crédito u otra información de pago registrada, según
                corresponda, en función de la frecuencia de facturación por
                excedente indicada en el formulario de pedido o según figure en
                el portal del cliente. Si la frecuencia de facturación por
                excedente no figura en el formulario de pedido, las tarifas por
                excedente se facturarán y vencerán de acuerdo con las
                condiciones establecidas. Podemos ofrecer adiciones para mejorar
                su uso de los servicios. Algunas adiciones pueden generar cargos
                adicionales, que se facturará a su cuenta.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Complementos: Algunas funciones y servicios se ofrecen como
                complementos de los servicios. Si añade una función o servicio
                que tiene una tarifa adicional, se le facturará ese importe
                adicional en cada ciclo de facturación mientras el complemento
                esté activo. Usted acepta pagar todos los impuestos aplicables
                como resultado de su uso de los servicios. Los impuestos se
                mostrarán por separado para ayudarle a identificar los impuestos
                adeudados. Si dispone de un número de exención fiscal o de
                identificación a efectos del IVA, es su responsabilidad
                facilitarnos dicha información.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Complementos: Algunas funciones y servicios se ofrecen como
                complementos de los servicios. Si añade una función o servicio
                que tiene una tarifa adicional, se le facturará ese importe
                adicional en cada ciclo de facturación mientras el complemento
                esté activo. Usted acepta pagar todos los impuestos aplicables
                como resultado de su uso de los servicios. Los impuestos se
                mostrarán por separado para ayudarle a identificar los impuestos
                adeudados. Si dispone de un número de exención fiscal o de
                identificación a efectos del IVA, es su responsabilidad
                facilitarnos dicha información.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Impuestos: Todas las Tarifas de los Servicios incluyen (a)
                impuestos, gravámenes, derechos u otras exacciones similares
                aplicables impuestas por una autoridad legal, gubernamental o
                reguladora en cualquier jurisdicción aplicable, incluidos los
                impuestos sobre ventas, uso, valor añadido, consumo,
                comunicaciones y (b) otros impuestos indirectos, incluidos los
                intereses y/o sanciones relacionados y otros derechos
                gubernamentales, así como cualquier otro coste, incluidos los
                costes de transacción o las comisiones por transferencias
                bancarias . Los impuestos, salvo las retenciones, se mostrarán
                como una partida separada en una factura. Usted es responsable
                de todos los impuestos asociados con los servicios y estas
                condiciones Usted se compromete a pagar todos los recargos y
                tasas de los operadores de telecomunicaciones asociados a su uso
                de los Servicios.
              </Box>{" "}
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Moneda: Todas las tarifas se pagarán en la divisa especificada
                en la orden de pedido aplicable o en la divisa indicada en el
                sitio web. Si los honorarios se pagan en divisas, el importe de
                dicho pago se calculará de acuerdo con el tipo de cambio oficial
                del día en que se realice el pago. Cualquier saldo o crédito
                prepagado caduca al cabo de un año.
              </Box>
              <Box
                style={{
                  fontFamily: "Rubik",
                  fontSize: 24,
                  fontWeight: 600,
                  textAlign: "justify",
                  marginTop: 59.4,
                  marginBottom: 26.66,
                }}
              >
                Términos de pago
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Condiciones de pago: Las obligaciones de pago no son cancelables
                y las tarifas de los servicios, los impuestos y los recargos por
                comunicaciones , una vez abonados, no son reembolsables. Salvo
                que se establezca lo contrario en la(s) Hoja(s) de Pedido. El
                usuario pagará las comisiones adeudadas en virtud de estas
                condiciones de acuerdo con el siguiente método de pago
                aplicable: (a) si opta por abonar las comisiones mediante
                tarjeta de crédito, usted declara y garantiza que está
                autorizado a utilizar dicha tarjeta de crédito, que todas y cada
                una de las comisiones pueden facturarse a dicha tarjeta de
                crédito y que el pago de dichas comisiones no será rechazado; o
                (b) si opta por recibir facturas y nosotros aprobamos que las
                reciba, se le enviarán facturas con la frecuencia establecida en
                el Formulario de pedido aplicable y usted abonará las comisiones
                adeudadas en un plazo de quince (15) días a partir de la fecha
                de la factura. Si no paga las cuotas en la fecha de vencimiento
                indicada, podemos cobrarle una cuota por demora, suspender su
                cuenta o ambas cosas.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Avisos de cobro: Si sigue sin pagar las Tasas después de que le
                enviemos un aviso por correo electrónico, podremos enviarle
                notificaciones de recordatorio de pago atrasado a través de
                medios de comunicación alternativos, como SMS y cualquier otro
                canal de comunicación disponible utilizando la información de
                contacto que nos haya facilitado. Si cree que las tarifas que le
                estamos cobrando son inexactas, tiene 15 días para escribirnos y
                disputar el cargo. Si coopera rápidamente con nosotros para
                resolver la disputa, no le cobramos ninguna tasa por demora ni
                suspenderemos sus servicios.
              </Box>
              <Box
                style={{
                  fontFamily: "Rubik",
                  fontSize: 24,
                  fontWeight: 600,
                  textAlign: "justify",
                  marginTop: 59.4,
                  marginBottom: 26.66,
                }}
              >
                Propiedad intelectual y datos
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Propiedad de los Servicios. Nosotros, o nuestros licenciantes,
                según corresponda, poseemos y nos reservamos todos los derechos,
                títulos e intereses, incluidos, entre otros, los derechos de
                propiedad intelectual, sobre los Servicios, la documentación y
                todas las modificaciones, extensiones, personalizaciones,
                scripts u otros trabajos derivados de los servicios y la
                documentación. Usted no podrá, sin previa autorización por
                escrito, realizar ingeniería inversa, copiar, disimular o
                descompilar los servicios, ni eliminar ningún aviso de
                copyright, marca comercial u otros derechos de propiedad
                contenidos en el Servicio. También somos propietarios de todos
                los datos generados a través de nuestros servicios, siempre que
                no le identifiquen directa o indirectamente a usted o a sus
                usuarios.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Reserva de Datos: Poseemos y nos reservamos todos los derechos
                de propiedad intelectual sobre cualquier dato que se derive del
                uso de los Servicios, incluidos los datos que no le identifican
                directa o indirectamente a usted, a sus Afiliados o a los
                usuarios de su Aplicación de Cliente y, con sujeción a la
                legislación aplicable, los datos desidentificados y agregados de
                forma que no identifiquen su identidad ni la de los usuarios de
                su Aplicación de Cliente ante terceros . Le concedemos una
                licencia mundial, de duración limitada, no exclusiva,
                intransferible y libre de regalías durante el Período de
                vigencia aplicable para acceder y utilizar los Datos de
                VIDEOLINK únicamente para su uso de los Servicios y de
                conformidad con el Acuerdo. Usted nos envía sus datos para que
                nosotros podamos prestarle los servicios. Estos datos son suyos
                y a usted le pertenecen todos los derechos de propiedad
                intelectual sobre ellos. Nuestro acuerdo de protección de datos
                y nuestra declaración de privacidad detallan cómo utilizamos y
                protegemos sus datos. El Cliente es el propietario exclusivo y
                se reserva todos los derechos de propiedad intelectual de cada
                aplicación del cliente y de los datos del cliente. Usted nos
                otorga a nosotros el derecho a procesar los datos según sea
                necesario para prestar los servicios de manera coherente con
                estas condiciones, el acuerdo de procesamiento de datos y
                nuestra declaración de privacidad. Su aceptación de las
                presentes condiciones implica la aceptación de los términos del
                acuerdo de procesamiento de datos, que se incorpora a las
                presentes condiciones como anexo. Cuando utiliza nuestros
                servicios, nos concede una licencia limitada para utilizar sus
                datos con el fin de prestarle los servicios. No compartiremos la
                información confidencial de la otra parte, ni utilizaremos la
                información confidencial de la otra parte por ningún otro motivo
                que no sea el cumplimiento de las obligaciones derivadas de
                estas condiciones. Las únicas excepciones permitidas a esta
                restricción se describen en estas condiciones.
              </Box>
              <Box
                style={{
                  fontFamily: "Rubik",
                  fontSize: 24,
                  fontWeight: 600,
                  textAlign: "justify",
                  marginTop: 59.4,
                  marginBottom: 26.66,
                }}
              >
                Confidencialidad
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Definición: Por "Información Confidencial" se entenderá
                cualquier información o dato revelado por una Parte ("Parte
                Reveladora") a la otra ("Parte Receptora") que esté marcado como
                confidencial o que deba entenderse razonablemente como
                confidencial dada la naturaleza de la información y las
                circunstancias que rodean su revelación (por ejemplo,
                formularios de pedido, datos de clientes, precios). La
                información confidencial no incluye ninguna información que: (a)
                esté disponible públicamente de forma independiente; (b) fuera
                conocida legítimamente por la Parte Receptora antes de su
                divulgación por la Parte Divulgadora; (c) haya sido divulgada
                legítimamente a la Parte Receptora por otra parte que no se
                encuentre bajo ninguna obligación o incumplimiento de
                confidencialidad; o (d) haya sido desarrollada de forma
                independiente por o para la Parte Receptora sin utilizar o hacer
                referencia a la información confidencial de la Parte
                Divulgadora. En consecuencia A menos que se acuerde por escrito,
                la Parte Receptora no (a) utilizará ninguna información
                confidencial de la Parte Divulgadora para ningún fin que no sea
                el cumplimiento de los derechos y obligaciones de la Parte
                Receptora en virtud del contrato; ni (b) divulgará Información
                Confidencial a ningún tercero, excepto a entidades (por ejemplo,
                Filiales, contratistas, asesores legales) (colectivamente,
                "Representantes") que tengan una "necesidad de saber" para que
                la Parte Receptora cumpla con sus derechos y obligaciones en
                virtud de estos términos. Los representantes estarán obligados a
                proteger la información Confidencial en los mismos términos de
                confidencialidad que la Parte Receptora, y ésta será responsable
                de cualquier incumplimiento de dichas obligaciones por parte de
                los representantes.
              </Box>{" "}
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Divulgación Obligatoria: La Parte Receptora podrá divulgar la
                Información Confidencial de la Parte Divulgadora en la medida en
                que se vea obligada por la normativa, la ley, la citación, la
                orden judicial o las obligaciones contractuales con los
                proveedores de telecomunicaciones, siempre que * la Parte
                Receptora notifique con prontitud a la Parte Divulgadora la
                divulgación obligatoria en la medida en que la ley lo permita, *
                la Parte Receptora divulgue únicamente la información
                confidencial exigida legalmente,{" "}
              </Box>
              <Box
                style={{
                  fontFamily: "Rubik",
                  fontSize: 24,
                  fontWeight: 600,
                  textAlign: "justify",
                  marginTop: 59.4,
                  marginBottom: 26.66,
                }}
              >
                Representaciones, garantías y descargo de responsabilidad
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Sus Declaraciones y Garantías: Usted declara y garantiza que ha
                obtenido todos los permisos o consentimientos necesarios para
                entregarnos los datos del cliente para su uso y divulgación de
                conformidad con el presente Contrato. Prometemos que los
                Servicios funcionarán tal y como los describimos en nuestra
                documentación.{" "}
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Nuestras Declaraciones y Garantías: Declaramos y garantizamos
                que los Servicios funcionarán sustancialmente tal y como se
                establece en la Documentación aplicable. Nuestra única
                obligación, y su único y exclusivo recurso, en caso de cualquier
                fallo en este sentido realizaremos los esfuerzos comercialmente
                razonables para corregir el fallo material.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Autoridad: Cada una de las partes declara y garantiza que tiene
                el derecho y la autoridad legales para celebrar el Acuerdo,
                cumplir sus obligaciones en virtud del Acuerdo y conceder los
                derechos y licencias descritos en el Acuerdo. Ambos nos
                comprometemos a cumplir todas las leyes y normativas
                anticorrupción y aplicables. Usted también se compromete a
                notificarnos rápidamente cualquier infracción de estas leyes de
                la que tenga conocimiento.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Leyes Anticorrupción y de Comercio Internacional: Cada una de
                las Partes garantiza que cumplirá todas las leyes
                anticorrupción, contra el blanqueo de dinero, y otras leyes,
                reglamentos y órdenes gubernamentales de comercio.
              </Box>
              <Box
                style={{
                  fontFamily: "Rubik",
                  fontSize: 24,
                  fontWeight: 600,
                  textAlign: "justify",
                  marginTop: 59.4,
                  marginBottom: 26.66,
                }}
              >
                La terminación del contrato
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                ocurrirá, cuando las fechas previstas en el contrato ocurran,
                sin embargo, el contrato podría renovarse si treinta (30) días
                antes de su expiración, el Cliente expresa su deseo de
                continuarlo y las partes proceden a acordar las nuevas
                condiciones monetarias (precio) del servicio.
              </Box>{" "}
              <Box
                style={{
                  fontFamily: "Rubik",
                  fontSize: 24,
                  fontWeight: 600,
                  textAlign: "justify",
                  marginTop: 59.4,
                  marginBottom: 26.66,
                }}
              >
                La resolución del contrato
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Incumplimiento Grave y otros motivos: Cualquiera de las Partes
                podrá resolver el Pedido afectado o los servicios utilizados por
                usted en caso de incumplimiento sustancial si, tras notificar
                por escrito el incumplimiento, la otra Parte no lo subsane en el
                plazo de quince (15) días. En caso de incumplimiento material
                por su parte, también podremos: * rescindir el Contrato, *
                Cerrar todas sus cuentas y/o * prohibirle crear cuentas nuevas.
                También podremos rescindir o suspender el presente Contrato o la
                prestación de determinados Servicios con efecto inmediato
                notificándose en caso de que tengamos razones fundadas para
                creer que su uso de los Servicios constituirá un incumplimiento
                de las condiciones de aplicación de terceros * de los términos
                del presente Contrato; * es contrario a las leyes, reglamentos u
                orden público aplicables; * incluye la transmisión de contenido
                inapropiado
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Cualquiera de los dos podrá rescindir estas condiciones si el
                otro es parte en un procedimiento de quiebra o similar.
              </Box>{" "}
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Rescisión por insolvencia: Cualquiera de las Partes podrá
                rescindir el presente Contrato (y nosotros podremos cerrar su
                cuenta) mediante notificación por escrito en caso de que la otra
                Parte sea objeto de una petición de quiebra o de cualquier otro
                procedimiento relacionado con la insolvencia, la administración
                judicial o la liquidación.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Incumplimiento Grave y otros motivos : Cualquiera de las Partes
                podrá resolver el Pedido afectado o los servicios utilizados por
                usted en caso de incumplimiento sustancial si, tras notificar
                por escrito el incumplimiento, la otra Parte no lo subsane en el
                plazo de quince (15) días. En caso de incumplimiento material
                por su parte, también podremos : * rescindir el Contrato, *
                Cerrar todas sus cuentas y/o * prohibirle crear cuentas nuevas.
                También podremos rescindir o suspender el presente Contrato o la
                prestación de determinados Servicios con efecto inmediato
                notificándose en caso de que tengamos razones fundadas para
                creer que su uso de los Servicios constituirá un incumplimiento
                de las condiciones de aplicación de terceros * de los términos
                del presente Contrato; * es contrario a las leyes, reglamentos u
                orden público aplicables; * incluye la transmisión de contenido
                inapropiado
              </Box>{" "}
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Si usted rescinde estas condiciones porque nosotros las hemos
                incumplido de forma significativa, le reembolsaremos cualquier
                cantidad que nos haya pagado por adelantado. Si rescindimos
                estas condiciones porque usted las ha incumplido de forma
                significativa, seguirá estando obligado a pagarnos el resto de
                las tasas adeudadas.
              </Box>{" "}
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Una vez rescindidas estas condiciones, usted se compromete a
                dejar de utilizar inmediatamente nuestros Servicios, y devolverá
                o destruirá todos nuestros datos e información confidencial.
                Nosotros también eliminaremos todos sus datos e información
                confidencial. Algunas excepciones pueden deberse a la
                legislación aplicable.
              </Box>{" "}
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Consecuencias de la Rescisión o Expiración: En la fecha efectiva
                de rescisión o expiración del Contrato o de cualquier Orden de
                Pedido: (a) todos los derechos, licencias y suscripciones que se
                le concedan en virtud de cualquier Formulario de pedido afectado
                y del Acuerdo quedarán inmediatamente rescindidos; (b) usted
                cesará inmediatamente todo uso y acceso a su cuenta y a los
                Servicios correspondientes; (c) usted devolverá o destruirá
                inmediatamente (a nuestra discreción) todos los Datos de
                VIDEOLINK, nuestra Información confidencial y cualquier ID de
                usuario que estén en su posesión; y (d) eliminaremos cualquier
                Información confidencial o Datos del cliente almacenados por
                nosotros en un plazo de cuarenta y cinco (45) días tras la fecha
                efectiva de expiración o rescisión, a menos que se aplique un
                periodo de retención legal diferente o que sea necesario para
                procesar o defender una reclamación legal, en cuyo caso solo
                conservaremos dicha información durante el tiempo necesario para
                resolver la reclamación o cumplir con la legislación aplicable.
                En caso de rescisión o caducidad de un Pedido, (c) y (d) no se
                aplicarán en la medida en que los Datos , la Información
                confidencial, los ID de usuario y los Datos del cliente sigan
                siendo necesarios en relación con el uso de otros Servicios que
                no sean los Servicios rescindidos o caducados.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Supervivencia de los Términos: Es posible que tengamos que
                actualizar estos términos para reflejar el estado actual de los
                Servicios. Si actualizamos los términos, se lo haremos saber. Si
                sigue utilizando nuestros Servicios después de que estos
                términos hayan cambiado y entrado en vigor, significa que ha
                aceptado dichos cambios y que son vinculantes para usted. Si no
                está de acuerdo con las condiciones modificadas, debe dejar de
                utilizar nuestros Servicios inmediatamente.
              </Box>
              <Box
                style={{
                  fontFamily: "Rubik",
                  fontSize: 24,
                  fontWeight: 600,
                  textAlign: "justify",
                  marginTop: 59.4,
                  marginBottom: 26.66,
                }}
              >
                Ley aplicable y resolución de litigios
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Legislación aplicable. Cualquier disputa, reclamación o
                controversia ("Disputas") que surja de o esté relacionada con
                este Contrato se regirá e interpretará de acuerdo con las leyes
                de la República Bolivariana de Venezuela, Las partes manifiestan
                que han leído íntegramente este Contrato, que lo entienden y que
                se sujetan a sus términos y condiciones. Asimismo, convienen en
                que este contrato constituye el único acuerdo entre ellas
                respecto de las materias objeto del mismo. Todo litigio relativo
                a la formación, validez, interpretación, firma, existencia,
                ejecución o terminación de este contrato y, en general, a la
                relación establecida entre las partes, será sometido a la
                legislación venezolana. Así, en caso de controversia,
                diferencia, conflicto o reclamación en cuanto al contrato, o en
                relación al mismo, las partes acuerdan que será resuelta en
                arbitraje, de conformidad con el procedimiento expedito,
                previsto en el Reglamento de Conciliación y Arbitraje del CEDCA,
                que LAS PARTES declaran conocer.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Notificaciones. Si necesita enviarnos una notificación en virtud
                de las presentes Condiciones, puede hacerlo por escrito a través
                del correo electrónico info@3dlinkweb.com . Si necesitamos
                enviarle una notificación en virtud de estas Condiciones, lo
                haremos por escrito a través de correo electrónico a la
                dirección de correo electrónico que designe en su cuenta. Es su
                responsabilidad mantener actualizadas y correctas todas las
                direcciones de correo electrónico asociadas a su cuenta.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Si cualquiera de nosotros no ejerce un derecho en virtud de
                estos términos, eso no significa que estemos renunciando a ese
                derecho.
              </Box>
              <Box
                style={{
                  textAlign: "justify",
                  marginBottom: 16,
                  fontFamily: "Rubik",
                }}
              >
                Cesión. Usted no podrá ceder, delegar ni transferir de ningún
                otro modo ninguno de sus derechos u obligaciones en virtud del
                presente Contrato, de cualquier Formulario de Pedido aplicable o
                de los contenidos en el portal del cliente del Sitio sin nuestro
                consentimiento previo por escrito. Podremos ceder, delegar o
                transferir de cualquier otro modo todos o algunos de nuestros
                derechos u obligaciones en virtud de las presentes Condiciones,
                de los formularios de pedido aplicables o de los contenidos en
                el portal del cliente del Sitio. Tras cualquier cesión,
                delegación o transferencia autorizada, todas las condiciones
                serán vinculantes para las entidades sucesoras.
              </Box>
            </Box>
          </Box>
        </Dialog>
      </>
    </Box>
  );
}

export default MobileView;
