import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";

function CustomRadio(props) {
  const BpIcon = styled("span")(({ theme }) => ({
    borderRadius: "50%",
    width: !props.isMobile ? 14 : props.height >= 1180 ? 28 : 14,
    height: !props.isMobile ? 14 : props.height >= 1180 ? 28 : 14,

    border: "1px solid #52596B",
  }));

  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: "#eb3e67",
    border: "1px solid #eb3e67",
    "input:hover ~ &": {
      backgroundColor: "#eb3e67",
    },
  });

  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export default CustomRadio;
