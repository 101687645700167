import { useState, useEffect, useContext } from "react";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";

import imageNotification from "../assets/imageNotification.svg";
import logo from "../assets/logo_videolink_mobile.svg";
import i18n from "../i18n";
import { EasyContext } from "../context";

function ReadEmailNotification() {
  const theme = useTheme();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();
  const { state } = useContext(EasyContext);
  const [lang, setLang] = useState(state.user ? state.user.lang : state.lang);

  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);
  return mobileBreakpoint ? (
    <Box
      style={{
        height: "100vh",
        background: "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
        overflow: "hidden",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        style={{
          fontFamily: "Rubik",
          fontWeight: 400,
          fontSize: 40,
          color: "#FFFFFF",
        }}
      >
        {t("notification.title")}
      </Box>
      <Box
        style={{
          fontFamily: "Rubik",
          marginTop: 18,
          fontWeight: 600,
          fontSize: 20,
          color: "#FFFFFF",
        }}
      >
        {t("notification.message")}
      </Box>

      <Box
        style={{
          marginTop: 100,
        }}
      >
        <img src={imageNotification} alt="" />
      </Box>
      <Box style={{ left: "2.5%", position: "absolute", bottom: "2.5%" }}>
        <img src={logo} alt="" />
      </Box>
    </Box>
  ) : (
    <Box
      style={{
        height: "100vh",
        background: "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
        backgroundSize: "cover",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: width < 1024 && "center",
        padding: 16,
      }}
    >
      <Box
        style={{
          fontFamily: "Rubik",
          fontWeight: 400,
          fontSize: 40,
          color: "#FFFFFF",
          textAlign: "center",
        }}
      >
        {t("notification.title")}
      </Box>
      <Box
        style={{
          fontFamily: "Rubik",
          marginTop: 18,
          fontWeight: 500,
          fontSize: height >= 1180 ? 26 : 20,
          color: "#FFFFFF",
          textAlign: "center",
        }}
      >
        {t("notification.message")}
      </Box>
      <Box style={{ marginTop: 36 }}>
        <img src={logo} alt="" />
      </Box>
      <Box
        style={{
          marginTop: 24,
          width: "100%",
          textAlign: "center",
        }}
      >
        <img
          src={imageNotification}
          style={{ width: width < 1024 ? "100%" : "60%" }}
          alt=""
        />
      </Box>
    </Box>
  );
}

export default ReadEmailNotification;
